import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.svg`
    cursor: pointer;
`

const Polygon1 = () => {
    return (
        <>
          <Wrapper width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.32" d="M10.6339 10.6339L35 0.541196L59.3661 10.6339L69.4588 35L59.3661 59.3661L35 69.4588L10.6339 59.3661L0.541196 35L10.6339 10.6339Z" stroke="#FFFCF6"/>
          </Wrapper>
        </>
    );
}

export default Polygon1;
