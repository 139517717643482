import React from 'react';

const Star = () => {
    
    return (
        <>
            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.5 0L18.8519 13.3391L27.7862 3.3422L21.0394 14.9284L34.1435 12.0922L21.875 17.5L34.1435 22.9078L21.0394 20.0716L27.7862 31.6578L18.8519 21.6609L17.5 35L16.148 21.6609L7.21376 31.6578L13.9605 20.0716L0.85651 22.9078L13.125 17.5L0.85651 12.0922L13.9605 14.9284L7.21376 3.3422L16.148 13.3391L17.5 0Z" fill="#FCD043"/>
            </svg>
        </>
    );
}

export default Star;
