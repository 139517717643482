import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.svg`
  position: relative;
  right: 55px;
  top: 10px;
`;

const Icon = () => {
  return (
    <Wrapper
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.72897 18.3583C4.62549 18.2054 3.90287 17.1175 4.18921 16.0403C4.45646 15.0349 5.54418 14.4956 6.50693 14.891L19.6448 20.2876L5.72897 18.3583Z'
        fill='#FCD043'
      />
      <path
        d='M21.4761 6.41049C21.6244 5.28311 22.7283 4.54663 23.8254 4.84302C24.8495 5.11966 25.4008 6.2343 24.9988 7.21533L19.6538 20.2601L21.4761 6.41049Z'
        fill='#FCD043'
      />
      <path
        d='M11.2034 9.08286C10.5197 8.18466 10.7708 6.89595 11.7382 6.33861C12.6412 5.81843 13.8021 6.21735 14.2104 7.18811L19.704 20.2498L11.2034 9.08286Z'
        fill='#FCD043'
      />
      <path
        d='M30.823 11.7421C31.7155 11.0615 33.0084 11.3286 33.5766 12.3109C34.1068 13.2277 33.7196 14.3954 32.7519 14.7977L19.7039 20.2218L30.823 11.7421Z'
        fill='#FCD043'
      />
      <path
        d='M33.5414 22.181C34.6449 22.3339 35.3675 23.4218 35.0812 24.499C34.8139 25.5044 33.7262 26.0437 32.7635 25.6483L19.6257 20.2517L33.5414 22.181Z'
        fill='#FCD043'
      />
      <path
        d='M17.7943 34.1288C17.646 35.2562 16.5421 35.9927 15.4449 35.6963C14.4209 35.4196 13.8696 34.305 14.2716 33.324L19.6166 20.2792L17.7943 34.1288Z'
        fill='#FCD043'
      />
      <path
        d='M28.1808 31.3964C28.8646 32.2946 28.6134 33.5833 27.646 34.1406C26.7431 34.6608 25.5822 34.2619 25.1739 33.2911L19.6801 20.2294L28.1808 31.3964Z'
        fill='#FCD043'
      />
      <path
        d='M8.56296 28.7311C7.67051 29.4117 6.37756 29.1446 5.80941 28.1623C5.27915 27.2455 5.66636 26.0778 6.63406 25.6755L19.6821 20.2513L8.56296 28.7311Z'
        fill='#FCD043'
      />
    </Wrapper>
  );
};

export default Icon;
