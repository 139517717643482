import React from 'react';
import Icon from './Icon';

const RoundedText = () => {
  return (
    <>
      <svg
        className='text'
        width='163'
        height='170'
        viewBox='0 0 163 170'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M74.6532 156.617L71.1738 163.82L68.819 162.761C68.3013 162.528 67.9166 162.235 67.665 161.883C67.4099 161.531 67.2754 161.148 67.2617 160.733C67.2455 160.318 67.3388 159.9 67.5415 159.481C67.7443 159.061 68.015 158.722 68.3539 158.463C68.6916 158.207 69.0814 158.058 69.5233 158.017C69.964 157.979 70.4421 158.075 70.9575 158.307L72.81 159.14L72.5245 159.731L70.6899 158.906C70.3075 158.734 69.9565 158.662 69.6368 158.689C69.3159 158.719 69.0343 158.829 68.7919 159.02C68.5471 159.209 68.3488 159.461 68.197 159.776C68.0452 160.09 67.9728 160.398 67.9798 160.701C67.9844 161.003 68.0797 161.281 68.2659 161.536C68.4497 161.79 68.7352 162.004 69.1223 162.178L70.7931 162.93L73.987 156.317L74.6532 156.617Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M66.3121 152.373L61.786 158.97L59.6409 157.533C59.1693 157.217 58.8337 156.868 58.6342 156.487C58.4311 156.106 58.3519 155.713 58.3966 155.308C58.4376 154.902 58.59 154.508 58.8538 154.123C59.1175 153.739 59.4314 153.454 59.7955 153.269C60.1575 153.082 60.5531 153.011 60.9824 153.058C61.4117 153.104 61.8601 153.284 62.3274 153.597L64.1545 154.821L63.7765 155.372L61.9657 154.158C61.6195 153.926 61.2927 153.791 60.9853 153.751C60.6757 153.709 60.3925 153.756 60.1356 153.891C59.8766 154.025 59.6483 154.236 59.4509 154.523C59.2535 154.811 59.1384 155.101 59.1058 155.392C59.0716 155.686 59.1298 155.97 59.2802 156.243C59.4285 156.514 59.6779 156.768 60.0284 157.003L61.5504 158.022L65.7053 151.967L66.3121 152.373ZM61.3396 153.386L61.746 149.314L62.447 149.784L62.0244 153.845L61.3396 153.386Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M58.8337 146.788L53.3674 152.629L49.9989 149.437L50.4473 148.958L53.2856 151.647L55.3435 149.448L52.6838 146.928L53.1322 146.449L55.7919 148.969L57.8551 146.764L54.9601 144.021L55.4085 143.542L58.8337 146.788Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M46.04 144.918L45.5474 144.233L49.4072 137.668L49.3662 137.611L42.117 139.463L41.6243 138.778L48.0008 133.947L48.4022 134.505L43.2556 138.405L43.2921 138.455L50.0148 136.747L50.4025 137.286L46.832 143.377L46.8685 143.428L52.0151 139.529L52.4165 140.087L46.04 144.918Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M38.7796 133.26L45.7912 129.408L46.1182 130.061L39.1065 133.913L38.7796 133.26Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M34.528 122.183L34.3029 121.488L39.2581 119.616C39.7673 119.424 40.2622 119.373 40.7428 119.464C41.221 119.556 41.646 119.775 42.0179 120.121C42.3865 120.466 42.6635 120.924 42.8489 121.497C43.0335 122.067 43.0803 122.612 42.9893 123.133C42.8983 123.654 42.6923 124.111 42.3715 124.505C42.0506 124.899 41.6355 125.192 41.1264 125.384L36.1712 127.255L35.9461 126.561L40.8538 124.707C41.2509 124.557 41.576 124.333 41.8293 124.034C42.0801 123.736 42.2415 123.39 42.3134 122.996C42.3845 122.599 42.3486 122.181 42.2058 121.74C42.0629 121.299 41.8492 120.947 41.5646 120.685C41.2801 120.422 40.9542 120.26 40.587 120.199C40.2166 120.136 39.8328 120.179 39.4357 120.329L34.528 122.183Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M32.9772 115.147L32.8781 114.309L39.4029 110.234L39.3946 110.164L32.1876 108.475L32.0884 107.637L39.9704 106.268L40.0512 106.95L33.6894 108.055L33.6967 108.117L40.3758 109.693L40.4538 110.352L34.4093 114.138L34.4166 114.2L40.7784 113.095L40.8592 113.778L32.9772 115.147Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M40.8706 94.6467L40.7304 95.3994L33.3371 91.4532L33.4801 90.6851L41.9388 88.9094L41.7987 89.6621L34.354 91.171L34.344 91.2248L40.8706 94.6467ZM37.9333 93.6893L38.6598 89.7876L39.31 89.8766L38.5835 93.7783L37.9333 93.6893Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M43.3566 84.4688L35.7319 82.0472L36.6038 79.6169C36.7955 79.0825 37.0598 78.6695 37.3967 78.3779C37.732 78.083 38.1096 77.9012 38.5296 77.8325C38.9505 77.7614 39.3831 77.7964 39.8273 77.9375C40.2716 78.0786 40.6399 78.2966 40.9323 78.5915C41.2221 78.8856 41.4071 79.2447 41.4872 79.6688C41.5648 80.0921 41.5082 80.5697 41.3173 81.1016L40.6314 83.0135L40.0059 82.8149L40.6853 80.9213C40.8269 80.5267 40.8698 80.1758 40.8141 79.8687C40.7559 79.5608 40.6194 79.3017 40.4045 79.0915C40.1905 78.8788 39.9172 78.7196 39.5846 78.614C39.252 78.5084 38.9321 78.4792 38.6249 78.5263C38.3186 78.571 38.0439 78.7008 37.8009 78.9158C37.5587 79.1283 37.366 79.4344 37.2227 79.8339L36.604 81.5583L43.6032 83.7812L43.3566 84.4688Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M47.0848 75.5145L46.7158 76.1853L41.0268 69.9333L41.4034 69.2488L49.898 70.4014L49.5289 71.0722L42.0697 70.0064L42.0434 70.0543L47.0848 75.5145ZM44.632 73.6129L46.5451 70.1356L47.1269 70.4393L45.2138 73.9166L44.632 73.6129Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M52.627 66.5141L46.2878 61.6341L47.8718 59.595C48.22 59.1466 48.5923 58.8351 48.9885 58.6603C49.3843 58.4819 49.7847 58.427 50.1898 58.4956C50.5944 58.5606 50.9813 58.7353 51.3507 59.0196C51.7201 59.304 51.9862 59.6321 52.1491 60.004C52.3136 60.3738 52.3587 60.77 52.2843 61.1926C52.21 61.6151 52.0003 62.0485 51.6552 62.4928L50.3061 64.2296L49.7768 63.8221L51.1139 62.1007C51.3695 61.7717 51.5273 61.4561 51.5873 61.1539C51.6489 60.8496 51.6201 60.5662 51.5009 60.3036C51.3833 60.0389 51.1863 59.8001 50.9098 59.5872C50.6333 59.3744 50.3495 59.243 50.0585 59.1931C49.7654 59.1417 49.4767 59.1823 49.1922 59.315C48.9094 59.4456 48.6386 59.6775 48.3798 60.0107L47.2559 61.4575L53.0751 65.9373L52.627 66.5141ZM51.9308 61.5267L55.9985 62.1737L55.481 62.84L51.4252 62.1776L51.9308 61.5267Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M53.6117 54.0222L53.1661 53.5404L57.3729 49.5464L57.8185 50.0282L55.98 51.7737L60.9671 57.1644L60.4374 57.6673L55.4503 52.2766L53.6117 54.0222Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M61.968 45.8694L62.6696 45.4007L68.7741 49.6995L68.8326 49.6604L67.5548 42.1373L68.2564 41.6687L72.4939 48.4543L71.9222 48.8361L68.502 43.3593L68.4501 43.394L69.6257 50.3702L69.0735 50.7391L63.4089 46.7616L63.3569 46.7963L66.7771 52.2731L66.2054 52.655L61.968 45.8694Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M76.6537 46.3104L73.7456 38.8577L77.9892 36.9798L78.2278 37.5911L74.6521 39.1735L75.7469 41.9791L79.0976 40.4964L79.3361 41.1078L75.9855 42.5905L77.0831 45.4035L80.7302 43.7895L80.9688 44.4009L76.6537 46.3104Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M90.0752 33.3273L91.6299 41.1748L90.9408 41.3494L85.0571 35.9389L84.9965 35.9543L86.2984 42.5258L85.5903 42.7052L84.0356 34.8577L84.721 34.6841L90.6213 40.0984L90.6819 40.083L89.3785 33.5039L90.0752 33.3273Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M95.9545 33.0866L95.9398 32.4305L101.724 31.9859L101.738 32.642L99.2105 32.8363L99.3744 40.1782L98.646 40.2342L98.4822 32.8923L95.9545 33.0866Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M111.265 34.4547C111.28 34.0097 111.132 33.6422 110.82 33.3521C110.507 33.062 110.088 32.8947 109.561 32.8502C109.19 32.8188 108.854 32.8543 108.554 32.9568C108.257 33.0596 108.013 33.2164 107.821 33.4273C107.633 33.6358 107.519 33.882 107.481 34.1659C107.453 34.3749 107.473 34.5619 107.543 34.727C107.612 34.8921 107.716 35.0379 107.853 35.1644C107.994 35.2886 108.152 35.3985 108.328 35.4944C108.507 35.5904 108.688 35.6736 108.873 35.7441L109.681 36.0552C109.926 36.1464 110.168 36.2569 110.408 36.3868C110.648 36.5167 110.861 36.6731 111.049 36.8561C111.24 37.0367 111.383 37.251 111.478 37.4992C111.576 37.745 111.602 38.0331 111.557 38.3634C111.5 38.7866 111.338 39.1579 111.072 39.4773C110.805 39.7967 110.452 40.0383 110.013 40.202C109.573 40.3633 109.066 40.4195 108.49 40.3707C107.947 40.3249 107.489 40.196 107.113 39.9841C106.738 39.7696 106.458 39.4941 106.272 39.1573C106.087 38.8206 106.009 38.442 106.037 38.0215L106.784 38.0848C106.768 38.3992 106.836 38.6725 106.988 38.9046C107.14 39.1368 107.354 39.3219 107.629 39.46C107.905 39.5956 108.221 39.6785 108.58 39.7088C108.977 39.7424 109.338 39.7065 109.665 39.601C109.995 39.4932 110.264 39.328 110.472 39.1055C110.684 38.8807 110.811 38.6096 110.854 38.2922C110.891 38.0238 110.851 37.7933 110.735 37.6009C110.62 37.4058 110.445 37.2357 110.21 37.0905C109.978 36.9455 109.703 36.8114 109.387 36.688L108.471 36.3286C107.871 36.0899 107.42 35.7921 107.12 35.4352C106.819 35.0782 106.703 34.6456 106.772 34.1372C106.83 33.7114 106.995 33.3469 107.267 33.0437C107.542 32.738 107.888 32.5115 108.304 32.364C108.723 32.2142 109.178 32.16 109.669 32.2015C110.164 32.2434 110.59 32.3709 110.947 32.5838C111.304 32.7967 111.573 33.0713 111.753 33.4076C111.936 33.7415 112.008 34.1104 111.969 34.5143L111.265 34.4547Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M123.566 43.5552L124.88 35.428L125.563 35.7172L124.368 42.6775L124.425 42.7019L128.959 37.1542L129.736 37.483L128.541 44.4432L128.598 44.4676L133.132 38.9199L133.819 39.2106L128.469 45.6299L127.75 45.3255L128.867 38.4337L128.82 38.4139L124.286 43.8596L123.566 43.5552Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M134.678 49.2237L137.622 41.4862L138.224 41.9197L135.635 48.531L135.685 48.5675L141.217 44.0735L141.902 44.5664L139.313 51.1776L139.363 51.2141L144.895 46.7202L145.501 47.156L139 52.3335L138.366 51.8772L140.865 45.3165L140.824 45.2869L135.312 49.68L134.678 49.2237Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M148.11 55.9348L147.641 56.3944L145.337 53.9931L145.805 53.5335L148.11 55.9348Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
        <path
          d='M148.348 62.183L147.914 61.5529L156.027 59.2295L156.471 59.8725L151.664 66.9859L151.229 66.3558L155.498 60.1334L155.467 60.0884L148.348 62.183ZM151.138 60.8277L153.392 64.0939L152.862 64.481L150.608 61.2148L151.138 60.8277Z'
          fill='#FFFCF6'
          fillOpacity='0.55'
        />
      </svg>
      <Icon />
    </>
  );
};

export default RoundedText;
