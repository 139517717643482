import React from 'react';

const Polygon5 = () => {
  return (
    <svg
      width='116'
      height='120'
      viewBox='0 0 116 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M58 0L93.2671 11.459L115.063 41.459V78.541L93.2671 108.541L58 120L22.7329 108.541L0.936607 78.541V41.459L22.7329 11.459L58 0Z'
        fill='#FCD043'
      />
    </svg>
  );
};

export default Polygon5;
